<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Rekap Absensi</strong>
              </h5>
            </template>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input class="mr-3"
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                >

                <b-button variant="success" v-b-modal.modal-input-csv
                  ><CIcon name="cil-plus" /> Upload File CSV</b-button
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-alert dismissible fade :show="showing" :variant="variant">{{
                  msg
                }}</b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Pilih Bulan"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    v-model="bulan"
                    :options="listBulan"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="Pilih Tahun"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    v-model="tahun"
                    :options="listTahun"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button block class="btn-success" @click="getData()">
                  Cari</b-button
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" offset-md="6">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail Absen'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), (detail = !detail)"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-detail :data="data" :detail="detail" :bulan="bulan" :tahun="tahun" />
    <modal-input
      :listKaryawan="listKaryawan"
      :listKeterangan="listKeterangan"
      @alertFromChild="triggerAlert($event)"
    />
    <modal-input-csv />
    <!--<modal-edit />
    <modal-delete /> -->
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalDetail from "../../../component/hcms/rekapAbsensi/modalDetail.vue";
import modalInput from "../../../component/hcms/rekapAbsensi/modalInput.vue";
import modalInputCsv from "../../../component/hcms/rekapAbsensi/modalInputCsv.vue";
import moment from "moment";
// import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  components: {
    // Multiselect,
    modalInput,
    modalInputCsv,
    modalDetail,
  },
  name: "rekapAbsensi",
  data() {
    return {
      divisi: [],
      listKaryawan: [],
      listKeterangan: [],
      data: "",
      detail: false,
      bulan: null,
      tahun: null,
      listBulan: [
        { value: "01", text: "Januari" },
        { value: "02", text: "Februari" },
        { value: "03", text: "Maret" },
        { value: "04", text: "April" },
        { value: "05", text: "Mei" },
        { value: "06", text: "Juni" },
        { value: "07", text: "Juli" },
        { value: "08", text: "Agustus" },
        { value: "09", text: "September" },
        { value: "10", text: "Oktober" },
        { value: "11", text: "November" },
        { value: "12", text: "Desember" },
      ],
      listTahun: [
        { value: 2022, text: "2022" },
        { value: 2023, text: "2023" },
        { value: 2024, text: "2024" },
        { value: 2025, text: "2025" },
        { value: 2026, text: "2026" },
        { value: 2027, text: "2027" },
        { value: 2028, text: "2028" },
        { value: 2029, text: "2029" },
        { value: 2030, text: "2030" },
        { value: 2031, text: "2031" },
      ],
      items: [
        {
          no: "-",
          nama: "-",
          posisi: "-",
          masuk: "-",
          alfa: "-",
          cutiIjin: "-",
          sakit: "-",
        },
      ],
      msg: "",
      variant: "",
      showing: false,
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "totalBerangkat",
          label: "Kehadiran",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "jumlahIjin",
          label: "Ijin",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "masukTelat",
          label: "Terlambat",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "keluarTelat",
          label: "Pulang",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "istirahatTelat",
          label: "After Break",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getNow();
    this.getKaryawan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getNow() {
      let vm = this;
      vm.bulan = moment().format("MM");
      vm.tahun = moment().format("YYYY");
      this.getData();
    },
    async getData() {
      let vm = this;
      this.tableBusy = true;
      let listAbsen = await axios.post(
        ipBackend + "absensiKaryawanMobile/rekapAbsenPerBulan",
        { bulan: vm.bulan, tahun: vm.tahun }
      );
      // console.log(listAbsen.data.data);
      this.items = listAbsen.data.data.map((item, idx) => {
        item.no = idx + 1;

        return item;
      });
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    async getKaryawan() {
      let listKaryawan = await axios.get(ipBackend + "dataKaryawan/list");
      this.listKaryawan = listKaryawan.data.data;
    },
    async getKeterangan() {
      let listKeterangan = await axios.get(ipBackend + "masterCuti/list");
      this.listKeterangan = listKeterangan.data.data;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getNow();
    },
  },
};
</script>
